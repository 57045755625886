// Nicked from: https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data
export function createFunctionWithTimeout(callback, opt_timeout) {
  let called = false

  function fn() {
    if (!called) {
      called = true
      callback()
    }
  }

  setTimeout(fn, opt_timeout || 1000)
  return fn
}
